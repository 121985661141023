<template>
  <div class="screen-loader-wrapper" v-if="loading">
    <div class="screen-loader">
      <!-- <img src="../../assets/clebex-logo-glyph-100x100.png" alt="Clebex logo" /> -->
      <svg class="circular-bg" viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" stroke-width="2" fill="none" />
      </svg>
      <svg class="circular" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ScreenLoaderWrapper",
  computed: {
    ...mapState("loader", ["loading"])
  }
};
</script>
